// Customizable Area Start
import React from "react";
import { Typography } from '@builder/component-library';
import FingerprintPaymentAuthentication2Controller, { Props, configJSON } from "./FingerprintPaymentAuthentication2Controller";
import {styles} from "./FingerprintPaymentAuthentication2.web";
export default class LogInPage extends FingerprintPaymentAuthentication2Controller {
    constructor(props: Props) {
        super(props);       
    }    
    render() {
        return (
            <div>
                {this.state.isAlreadyLogIn ?

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={styles.mainWrapper}>
                            <Typography style={styles.titleText}>{configJSON.labelSignupTitle}</Typography>
                            <Typography style={styles.bodyText}>{configJSON.labelSignupBody}</Typography>
                            <div>
                                <h2>Login</h2>
                                <button onClick={this.handleBeginLogin} data-test-id="logInBtn" type="submit" style={styles.buttonStyle}>Log In </button>
                                <div style={{ color: 'green' }}>{this.state.successMessage}</div>
                                <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={styles.mainWrapper}>
                            <Typography style={styles.titleText}>{configJSON.labelSignupTitle}</Typography>
                            <Typography style={styles.bodyText}>{configJSON.labelSignupBody}</Typography>
                            <div>
                                <h1>{this.state.step === "mobile" ? "Enter Registered Mobile Number" : "Enter OTP"}</h1>

                                {this.state.step === "mobile" ? (
                                    <form data-test-id="submitOTPForm" onSubmit={this.handleSendLoginOTP}>
                                        <input
                                            type="text"
                                            placeholder="Mobile Number"
                                            data-test-id="mobileNumberInput"
                                            value={this.state.mobileNumber}
                                            onChange={(e) => this.handleChange('mobileNumber', e.target.value)}
                                            style={styles.inputStyle}
                                        />
                                        <button type="submit" style={styles.buttonStyle}>Send OTP</button>
                                    </form>
                                ) : (
                                    <form data-test-id="verifyOTPForm" onSubmit={this.handleVerifyOTP}>
                                        <input
                                            type="text"
                                            placeholder="Enter OTP"
                                            data-test-id="otpInput"
                                            value={this.state.pin}
                                            onChange={(e) => this.handleChange('pin', e.target.value)}
                                            style={styles.inputStyle}
                                        />
                                        <button type="submit" style={styles.buttonStyle}>Submit OTP</button>
                                    </form>
                                )}
                                <div style={{ color: 'green' }}>{this.state.successMessage}</div>
                                <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        );
    }
}
// Customizable Area End