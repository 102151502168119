import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  getStorageData,
  removeStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
export const configJSON = require("./config");
interface ApiParams {
  header: string 
  endPoint: string
  method: string 
  body?: string | FormData;
}
export const bufferToBase64URLString = (buffer: ArrayBuffer): string => {
  let base64String = btoa(String.fromCharCode(...new Uint8Array(buffer)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
  const paddingIndex = base64String.indexOf('=');
  return paddingIndex > -1 ? base64String.substring(0, paddingIndex) : base64String;
};
export const base64URLStringToBuffer = (base64URLString: string): Uint8Array => {
  const padding = '==='.slice(0, (4 - (base64URLString.length % 4)) % 4);
  const base64 = (base64URLString + padding).replace(/-/g, '+').replace(/_/g, '/');
  return Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
};
// Customizable Area End
interface CustomAuthenticatorResponse extends AuthenticatorResponse {
  attestationObject: ArrayBuffer;
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start 
  successMessage: string;
  errorMessage: string;
  mobileNumber: string;
  step: "mobile" | "otp";
  pin: string;
  token: any;
  otpVerified: boolean;
  isAlreadyLogIn : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FingerprintPaymentAuthentication2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start 
  btnExampleProps = {
    onPress: () => {
      this.setState({ txtSavedValue: 'A' });
    }
  }
  btnShowHideImageProps = {}
  btnShowHideProps = {}
  txtInputProps = {}
  loginApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      // Customizable Area Start 
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "",
      enableField: false,
      // Customizable Area Start
      successMessage: "",
      errorMessage: "",
      token: null,
      mobileNumber: "",
      pin: "",
      step: "mobile",
      otpVerified: false,
      isAlreadyLogIn: true
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleAPIResponse(message);

      if(apiRequestCallId === this.loginApiCallId) {
          return this.handleResponseForLogInAPi(responseJson)
      }
    }
  }

   apiCalling = ({ header, endPoint, method, body }: ApiParams) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
  }
  
   truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }
  }

  showMessage = (message: string, type: 'success' | 'error') => {
    const alertMessage = new Message(getName(MessageEnum.AlertMessage));
    alertMessage.addData(getName(MessageEnum.AlertMessage), message);

    if (type === 'success') {
      this.setState({ successMessage: message, errorMessage: "" });
    } else {
      this.setState({ successMessage: "", errorMessage: message });
    }

    runEngine.sendMessage(this.props.id, alertMessage);
  };

  handleChange = (field: keyof S, value: string) => {
    this.setState({ [field]: value } as unknown as Pick<S, keyof S>, () => this.setState({ errorMessage : ""}));
  };
  handleSendOTP = async (event: React.FormEvent) => {
    event.preventDefault();
    const { mobileNumber } = this.state;
    const header = { "Content-Type": "application/json" };

    const sendOTPMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    sendOTPMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    sendOTPMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    const payload = {
      data: {
        type: "sms_account",
        attributes: { full_phone_number: mobileNumber }
      }
    };
    sendOTPMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(payload));
    sendOTPMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "https://kwelio-532648-ruby.b532648.dev.eastus.az.svc.builder.cafe/account_block/accounts"
    );

    runEngine.sendMessage(this.props.id, sendOTPMessage);
  };

  handleResponseForLogInAPi = async (responseJson: any) => {
      if(responseJson && responseJson.meta) {
         const token = this.truthyValue(responseJson.meta.token)
         const otp = this.truthyValue(responseJson.meta.pin)
         alert(otp)
         if (token) {
          await setStorageData("authToken", token);
        }
        this.setState({ step: "otp", successMessage: "OTP sent successfully", errorMessage: "" });
      } else {
        this.setState({ successMessage: "", errorMessage: "Invaild Mobile Number" });
      }
  }

  handleSendLoginOTP = async (event: React.FormEvent) => {
    event.preventDefault();
    const { mobileNumber } = this.state;
    const header = { "Content-Type": "application/json" };
    const payload = {
      data: {
        attributes: { full_phone_number: mobileNumber, "login": "true"  }
      }
    };

    this.loginApiCallId = this.apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.logInApiEndPoint,
      method: configJSON.httpPostMethod,
      body: JSON.stringify(payload)
    })
  };

  handleVerifyOTP = async (event: React.FormEvent) => {
    event.preventDefault();
    const { pin } = this.state;
    const token = (await getStorageData("authToken"))?.trim();
    const header = { "token": token, "Content-Type": "application/json" };

    const verifyOTPMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    verifyOTPMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    verifyOTPMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    verifyOTPMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ pin }));
    verifyOTPMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "https://kwelio-532648-ruby.b532648.dev.eastus.az.svc.builder.cafe/account_block/sms_confirmation"
    );

    runEngine.sendMessage(this.props.id, verifyOTPMessage);
    this.easyLogin();
  };  
  handleBeginRegistration = async (type: string): Promise<void> => {
    const token = (await getStorageData("authToken"))?.trim();
    if (!token) throw new Error("Authentication token is missing");
    const header = {
      "Content-Type": "application/json",
      "token": token,
    };
    const optionsRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    optionsRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    optionsRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    optionsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "https://kwelio-532648-ruby.b532648.dev.eastus.az.svc.builder.cafe/bx_block_fingerprintpaymentauthentication2/biometric_authentication/options_for_registration"
    );
    runEngine.sendMessage(this.props.id, optionsRequestMessage);
  };


  beginCredentialCreation = async (createOptions: any) => {

    try {
      createOptions.challenge = base64URLStringToBuffer(createOptions.challenge);
      createOptions.user.id = base64URLStringToBuffer(createOptions.user.id);

      const publicKeyCredential = (await navigator.credentials.create({
        publicKey: createOptions,
      })) as PublicKeyCredential & { response: CustomAuthenticatorResponse };

      const payload = {
        type: 'public-key',
        id: publicKeyCredential.id,
        rawId: bufferToBase64URLString(publicKeyCredential.rawId),
        authenticatorAttachment: 'cross-platform',
        response: {
          clientDataJSON: bufferToBase64URLString(publicKeyCredential.response.clientDataJSON),
          attestationObject: bufferToBase64URLString(publicKeyCredential.response.attestationObject),
          transports: ['hybrid', 'internal'],
        },
        clientExtensionResults: {},
      };
      const registrationMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      const token = (await getStorageData("authToken"))?.trim();
      const header = {
        "Content-Type": "application/json",
        "token": token,
      };

      registrationMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
      registrationMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      registrationMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(payload));

      registrationMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "https://kwelio-532648-ruby.b532648.dev.eastus.az.svc.builder.cafe/bx_block_fingerprintpaymentauthentication2/biometric_authentication/register"
      );
      runEngine.sendMessage(this.props.id, registrationMessage);
    } catch (error: any) {
      this.showError(`Credential creation failed: ${error.message}`);
    }
  };

  handleBeginLogin = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {

    const token = (await getStorageData("authToken"))?.trim();
    if (!token) {
      this.setState({ isAlreadyLogIn : false})
      return
    }
    const header = {
      "Content-Type": "application/json",
      "token": token,
    };
    const optionsVerifyRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    optionsVerifyRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    optionsVerifyRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    optionsVerifyRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "https://kwelio-532648-ruby.b532648.dev.eastus.az.svc.builder.cafe/bx_block_fingerprintpaymentauthentication2/biometric_authentication/options_for_authentication"
    );
    runEngine.sendMessage(this.props.id, optionsVerifyRequestMessage);
  };

  handleVerifyLogin = async (verifyOptions: any)=> {     
    try {    
      verifyOptions.challenge = base64URLStringToBuffer(verifyOptions.challenge);     
      verifyOptions.allowCredentials = verifyOptions.allowCredentials.map((cred: Credential) => ({
        ...cred,
        id: base64URLStringToBuffer(cred.id),
      }));     
      const assertion = await navigator.credentials.get({ publicKey: verifyOptions }) as PublicKeyCredential;   
      
      const authResponse = assertion.response as AuthenticatorAssertionResponse;
      const assertionResponse = {
        id: assertion.id,
        rawId: bufferToBase64URLString(assertion.rawId),
        type: assertion.type,
        response: {
          clientDataJSON: bufferToBase64URLString(authResponse.clientDataJSON),
          authenticatorData: bufferToBase64URLString(authResponse.authenticatorData),
          signature: bufferToBase64URLString(authResponse.signature),
          userHandle: null,            
        },
        clientExtensionResults: {}, 
        controller: "sessions", 
        action: "callback", 
        session: {
          type: assertion.type, 
          id: assertion.id, 
          rawId: bufferToBase64URLString(assertion.rawId), 
          authenticatorAttachment: "cross-platform", 
          response: {
            clientDataJSON: bufferToBase64URLString(authResponse.clientDataJSON),
            authenticatorData: bufferToBase64URLString(authResponse.authenticatorData),
            signature: bufferToBase64URLString(authResponse.signature),
            userHandle: null, 
          },
          clientExtensionResults: {}, 
        },
      }; 
          
      const loginMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      const token = (await getStorageData("authToken"))?.trim();
      const header = {
        "Content-Type": "application/json",
        "token": token,
      };

      loginMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
      loginMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      loginMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(assertionResponse));

      loginMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "https://kwelio-532648-ruby.b532648.dev.eastus.az.svc.builder.cafe/bx_block_fingerprintpaymentauthentication2/biometric_authentication/authenticate"
      );
      runEngine.sendMessage(this.props.id, loginMessage);
    } catch (error: any) {         
      this.showError(`Credential creation failed: ${error.message}`);
    }
  };
  showError = (message: string) => {
    const errorMessage = new Message(getName(MessageEnum.AlertMessage));
    errorMessage.addData(getName(MessageEnum.AlertMessage), message);
    runEngine.sendMessage(this.props.id, errorMessage);
  }; 
  handleAPIResponse = async (message: Message) => {
    try {
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseData?.create_options) {
      const createOptions = responseData.create_options;
      await this.beginCredentialCreation(createOptions);
    }
    if (responseData?.challenge) {
      const verifyOptions = {
        challenge: responseData.challenge,
        timeout: responseData.timeout,  
        extensions: responseData.extensions,  
        allowCredentials: responseData.allowCredentials,  
        userVerification: responseData.userVerification, 
      };
      await this.handleVerifyLogin(verifyOptions);
    }
    if (responseData?.meta?.otp) {
      const token = responseData.meta.token;
      await removeStorageData("authToken");     
      if (token) {
        await setStorageData("authToken", token);
      }
      this.setState({ step: "otp", successMessage: "OTP sent successfully", errorMessage: "" });

    } else if (responseData?.meta?.message) {
      const signupToken = responseData?.meta?.token;
      if (signupToken) {
        await setStorageData("authToken", signupToken);
      }  
    }        
  } catch (error) {    
    this.showMessage("An unexpected error occurred. Please try again.", "error");
  }
  }
  easyLogin = () => {
    this.props.navigation.navigate("FingerprintPaymentAuthentication2");
  };
  alreadyEasyLogin = () => {
    this.props.navigation.navigate("LoginPage");
  }
  // Customizable Area End
}